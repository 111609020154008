<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container" style="margin-left: 24px;margin-right: 24px;">
        <div class="card">
          <br>
          <div id="panel" style="display:flex;justify-content: center;">
          
            <div v-for="(template_sign,key) in sign_documents"  :key="template_sign" style="position: relative;">
              <div v-show="template_sign.num_page == current_page" v-if="template_sign.user_id == getUser.id" :id="'signature_area-'+key" class="div-draw div-signature template_sign" :style="{'width' : template_sign.width+'px', 'height' : template_sign.height+'px','top' : template_sign.top+'px', 'left' : template_sign.left+'px','position': 'absolute'}" @click="openModalFirma(template_sign)">
                <div :id="'signature_area-'+key+'header'">
                  {{template_sign.description}}
                </div>
              </div>
              </div>
             <img  v-if="!loading" id="img-pdf" class="img-pdf-convert"  :src="baseURL+'/'+document_images[current_page]?.path">
          </div>
          <br>
          <div  style="display:flex;justify-content: center;">
            <nav aria-label="page navigation example">
              <ul class="pagination justify-content-center">
                <li  :class="{'page-item' : true , 'disabled' : (current_page+1) == 1 }">
                  <a class="page-link" @click="current_page--" tabindex="-1">Anterior</a>
                </li>
                <li class="page-item"><a class="page-link">{{current_page+1}}</a></li>
                <li :class="{'page-item' : true , 'disabled' : (current_page+1) >= document_images.length }">
                  <a class="page-link" @click="current_page++">Siguiente</a>
                </li>
              </ul>
            </nav>
          </div>
          <br><br>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import axios from 'axios'
import {mapGetters} from "vuex";
import $ from 'jquery'
import firmarModal from './firmarModal'
import { modalController, createAnimation } from '@ionic/vue';

export default defineComponent({
  name: 'AgregarColaborador',
  setup() {
    return { close }
  },
  data(){
    return { 
      baseURL: axios.defaults.baseURL,
      document_id: null,
      sign_documents: [],
      document_images: [],
      document: null,
      current_page: 0,
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },
  mounted(){
    this.document_id = this.$route.params.documento_id
    this.getSignatureAreas()
  },
  methods:{
     async openModalFirma(template_sign) {
    
      const modal = await modalController
        .create({
          component: firmarModal,
          cssClass: 'class-modal-firma',
          keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {
            template_sign : template_sign,
            document: this.document,
            document_id: this.document_id,
            user: this.getUser
          }
        })
      
      modal.present();
      
      modal.onDidDismiss().then((data) => {
        console.log(data)
      })
    },
    getSignatureAreas(){
      
      let template_signs;

      axios.get('/api/documentos/signature-areas/'+this.document_id)
      .then(res => {
        this.loading = false
      
        template_signs = res.data.data.sign_documents
        this.document_images = res.data.data.document_images
        this.document = res.data.data.document
        console.log(res)
      
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        var width = $('#img-pdf').width()
        var height = $('#img-pdf').height()
        
        template_signs.forEach((template_sign) => {
          template_sign.left = (template_sign.position_x * width) / 100
          template_sign.top = (template_sign.position_y * height) / 100
          template_sign.width = (template_sign.width * width) / 100
          template_sign.height = (template_sign.height * height) / 100
        })
        
        this.sign_documents = template_signs
      })
    },
    enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style type="text/css">

  .div-clientes{
    display: block;
    position: fixed;
    width: 100%;
    background: #fff;
    contain: content;
    z-index: 10;
    padding-left: 20px;
  }

 @media (min-width: 320px) and (max-width: 767px) {
    
    .popover-wide .popover-wrapper .popover-content {
      width: 90% !important;
      transform-origin: right top !important;
    }
    
  }  

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover, .sc-ion-select-popover-h ion-label.sc-ion-select-popover{
      text-align: center;
}
 @media (min-width: 768px) and (max-width: 1500px) {
    
    .popover-wide .popover-wrapper .popover-content {
      width: 38% !important;
      transform-origin: right top !important;
      text-align: right !important;
    }
    
  } 


ion-radio::part(container){
  display: none;
}
ion-select::part(text){

}

.class-modal-firma .modal-wrapper {    
    --height: 90%;
    position: absolute; 
   
    --width: 65%;
    border-radius: 25px;
    display: block;
 }

</style>

<style scoped>

  .template_sign{
    border: 1px solid #d3d3d3; 
    background-color: rgb(88, 130, 184);
  }

  
.div-draw {
  position: absolute;
  z-index: 9;
  text-align: center;
  color: #fff;
  border-radius: 10px;
 

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.div-draw-header {
  padding: 10px;
  z-index: 10;
  background-color: rgb(88, 130, 184);
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.div-draw-header:focus-visible {
  outline: none;
}
ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}


@media (min-width: 1000px) {
  
  .img-pdf-convert{
     height: 500px;
     width: 300px;
      border: 2px solid;
  }
} 

@media (min-width: 10px) {
  
  .img-pdf-convert{
     height: 400px;

     width: 200px;
    border: 2px solid;
  }
} 

@media (min-width: 768px) {
 .img-pdf-convert{
     height: 600px;

     width: 480px;
    border: 2px solid;
  }
}

@media (min-width: 992px) {
  .img-pdf-convert{
     height: 1000px;

     width: 780px;
    border: 2px solid;
  }
}

@media (min-width: 1200px) {
  .img-pdf-convert{
     height: 1000px;

     width: 780px;
    border: 2px solid;
  }
}

@media (min-width: 1400px) {
  .img-pdf-convert{
     height: 1400px;

     width: 1200px;
    border: 2px solid;
  }
}

.p-clientes{
  font-weight: bold;
  color: #000;
}

.selected{
background-color: rgb(7 66 143);
}

.selected .div-draw-header{
background-color: rgb(7 66 143);
}



</style>