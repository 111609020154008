<template>
  <ion-content>
    <div style="margin-top: 100px;">
      <signature-pad
        @input="onInput"
        :modelValue="signatureFile"
        :width="700"
        :height="200"
        :customStyle="customStyle"
        :saveType="saveType"
        :saveOutput="saveOutput"
        :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
        ref="signaturePad"
        >
      </signature-pad>
      
      <div style="display:flex;justify-content: center;">
        <ion-button color="dark" @click="showDocument()">
          Ver documento a firmar
        </ion-button>
        &nbsp;&nbsp;
        <ion-button color="dark" @click="clearSignature">
          Limpiar
        </ion-button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <form :action="axios.defaults.baseURL+'/api/documentos/firma-documento'" >
          <input type="hidden" name="documento_id">
        </form>
        <ion-button color="dark" @click="saveSignature">
          Subir Firmar
        </ion-button>
      </div>

    </div>
  </ion-content>
</template>

<script>

import { defineComponent, reactive, ref, toRefs } from 'vue';
import { close } from 'ionicons/icons';
import { modalController } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'
import SignaturePad from "vue3-signature-pad";

export default defineComponent({
  name: 'AgregarColaborador',
  props: ['document','document_id','template_sign','user'],
  components: { SignaturePad },
  data(){
    return{
      axios,
      signatureDataURL :ref(null),
      signatureFile:ref(null),
      signaturePad :ref(null),
    }
  },
  setup() {

    const state = reactive({
      customStyle: { border: "black 3px solid" },
      saveType: "image/png",
      saveOutput: "file",
    })
    
    return {
      ...toRefs(state),
      close
    };
  },
  mounted(){
    
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
     async saveSignature() {
      
      const signature = this.$refs.signaturePad.saveSignature();
      
      var loading = await toast.showLoading()

      await loading.present();

      var formData = new FormData();

      formData.append('firma',signature.file)
      formData.append('documento_id',this.document_id)
      formData.append('user_id',this.user.id)
    
      axios.post('/api/documentos/firma-documento',formData)
      .then(data => {
        loading.dismiss()
        this.closeModal()
        this.$toast.openToast("Firma de documento exitoso","success")
        this.$router.push('/siniestros')
        console.log(data)
      }).catch(err =>{
        //this.$router.go(0)
        loading.dismiss()
        console.log(err)
      })
      
    },
    showDocument(){
      window.open(axios.defaults.baseURL+'/'+this.document.path, "_blank"); 
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    undoSignature(){
      this.$refs.signaturePad.undoSignature();
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>
